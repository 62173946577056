export interface BaseUser {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  metadata?: any;
  enabled: boolean;
}

export interface User extends BaseUser {
  readonly role: Role;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
  OPERATOR = 'OPERATOR',
}

export const RolesLabel = new Map<string, string>([
  [Role.ADMIN, 'Amministratore'],
  [Role.USER, 'Utente'],
  [Role.OPERATOR, 'Operatore'],
]);

export interface UserRole {
  readonly key: Role;
  readonly value: string;
}
